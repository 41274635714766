import GoogleWalletBadge from "@basicare/common/src/Assets/Images/google-wallet-badge.svg";
import AppleWalletBadge from "@basicare/common/src/Assets/Images/apple-wallet-badge.svg";
import { isIOS, isMobile } from "react-device-detect";
import { ReactElement } from "react";

interface WalletBadgesProps {
  googlePassUrl?: string;
  applePassUrl?: string;
  appleQrData?: string;
  className?: string;
  mobileHeader?: ReactElement;
  desktopHeader?: ReactElement;
}

const WalletBadges = ({
  googlePassUrl,
  applePassUrl,
  appleQrData,
  className,
  mobileHeader,
  desktopHeader,
}: WalletBadgesProps) => {
  if (!googlePassUrl && !applePassUrl) {
    return null;
  }

  return (
    <div className={className}>
      {isMobile ? (
        <>
          {mobileHeader}
          {isIOS && applePassUrl && (
            <a
              className="wallet__pass-badge wallet__pass-badge--apple-mobile"
              href={applePassUrl}
              download="pass.pkpass"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppleWalletBadge} alt="Add to Apple Wallet" />
            </a>
          )}
          {googlePassUrl && (
            <a
              className="wallet__pass-badge"
              href={googlePassUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img src={GoogleWalletBadge} alt="Add to Google Wallet" />
            </a>
          )}
        </>
      ) : (
        <>
          {desktopHeader}
          {googlePassUrl && (
            <div>
              <a href={googlePassUrl} target="_blank" rel="noreferrer">
                <img src={GoogleWalletBadge} alt="Add to Google Wallet" />
              </a>
            </div>
          )}
          {appleQrData && (
            <>
              <div className="my-3">OR</div>
              <div className="mb-2">Scan the QR code from your device</div>
              <div className="mb-2">
                <img
                  className="wallet__pass-badge wallet__pass-badge--apple"
                  src={AppleWalletBadge}
                  alt="Scan the QR from your device to add membership card to the wallet"
                />
              </div>
              <div>
                <img
                  className="wallet__qr-code--apple"
                  src={appleQrData}
                  alt="QR code for adding to Apple Wallet"
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WalletBadges;

import { useReactable } from "@reactables/react";
import PaymentService from "Services/PaymentService";
import API from "Helpers/API";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";
import MainLayout from "Features/Shared/Layouts/MainLayout";
import PageContentLayout from "Features/Shared/Layouts/PageContentLayout";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { Form, Field } from "@reactables/react-forms";
import { ExtendedMeta } from "@jauntin/utilities";
import {
  AuthenticationFormState,
  RxAuthenticationForm,
} from "../../Shared/Components/RxAuthenticationForm";
import {
  EmailInput,
  IntegerNumberInput,
  PhoneInput,
  TextInput,
} from "@jauntin/react-ui";
import ScrollToTop from "Features/Shared/Components/ScrollToTop";
import { AuthenticationPayload } from "../../Shared/Models/authenticationForm.model";
import WalletBadges from "Features/Wallet/Components/WalletBadges";
import { WalletPassesResponse } from "Features/Wallet/Models/wallet.model";
import { LoadableState } from "@jauntin/reactables/dist/Features/Shared/Reducers";
import { AxiosResponse } from "axios";
import Button from "@basicare/common/src/Components/Themed/Button";

const paymentService = new PaymentService(new API());

const RequestWalletPassesLinks = () => {
  const [state, actions] = useReactable(RxAuthenticationForm, {
    action: (formValue: AuthenticationPayload) =>
      paymentService.requestWalletPassesLinks(formValue),
  });

  if (!state) return;

  interface FormState extends AuthenticationFormState {
    submission: LoadableState<AxiosResponse<WalletPassesResponse>>;
  }

  const {
    form: { root },
    submission,
  } = state as FormState;
  const walletData = submission.data?.data;

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || state.submitTouched) && error);

  return (
    <MainLayout>
      <PageContentLayout>
        {(() => {
          if (submission.error) {
            return (
              <ScrollToTop>
                <h1 className="text-uppercase mb-4">Oops!</h1>
                <p className="mb-4 text-center">
                  We're sorry, but the information you entered does not match
                  our records.
                  <br />
                  Please try submitting the form again or contact us directly
                  for assistance.
                  <br />
                  <Button
                    className="mt-3"
                    onClick={() => {
                      actions.reset();
                    }}
                  >
                    Back to form
                  </Button>
                </p>
              </ScrollToTop>
            );
          }

          if (submission.success && walletData)
            return (
              <ScrollToTop>
                <div className="mw--635 mh--300 d-flex flex-column justify-content-center align-items-center">
                  <h1 className="text-uppercase mb-4">Thank you!</h1>
                  <h5 className="mb-4 text-center">
                    Your membership details have been successfully verified.
                  </h5>
                  <WalletBadges
                    className="text-center"
                    googlePassUrl={walletData.googlePassUrl}
                    applePassUrl={walletData.applePassUrl}
                    appleQrData={walletData.appleQrData}
                    mobileHeader={
                      <div className="mb-3">
                        You can now add your membership card to your wallet:
                      </div>
                    }
                    desktopHeader={
                      <div className="mb-3">
                        You can now add your membership card to your wallet:
                      </div>
                    }
                  />
                  <p className="mt-4 text-center">
                    If you have any questions, please contact us
                    <br />
                    at <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
                    <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
                  </p>
                </div>
              </ScrollToTop>
            );

          const showActionBtn =
            root.valid ||
            state.submitTouched ||
            hasControlTouchError(state.form);

          return (
            <div className="payment-method-update">
              <h1 className="mb-4">
                Please provide your membership information below to request a
                link for adding your membership card to your wallet. The details
                you enter should match the latest information we have on record.
              </h1>
              <Form rxForm={[state.form, actions.form]}>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Field
                      name="subscriberNumber"
                      component={TextInput}
                      label="Membership Number"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <Field
                      name="phoneNumber"
                      component={PhoneInput}
                      label="Phone Number"
                      placeholder="XXX-XXX-XXXX"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Field
                      name="email"
                      component={EmailInput}
                      label="Email Address"
                      placeholder="your@gmail.com"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <Field
                      name="lastFour"
                      component={IntegerNumberInput}
                      inputMode="numeric"
                      label="Last 4 Digits of the Card Number"
                      placeholder="0000"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                </div>
              </Form>
              <div className="mt-4 d-flex justify-content-start">
                <TwoStageButton
                  actionEnabled={showActionBtn}
                  action={() =>
                    actions.submit({
                      formValue: root.value,
                    })
                  }
                  spinning={submission.loading}
                  disableAction={!root.valid}
                  touchSubmit={actions.touchSubmit}
                />
              </div>
              {submission.error && (
                <div className="form-error d-flex w-100 justify-content-center mt-3">
                  There was an error, please try again
                </div>
              )}
            </div>
          );
        })()}
      </PageContentLayout>
    </MainLayout>
  );
};

export default RequestWalletPassesLinks;

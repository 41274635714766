import { Reactable, ofTypes } from "@reactables/core";
import { RxToggle } from "@jauntin/reactables";
import { from, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RxRequest, RequestState, FormBuilders } from "@jauntin/reactables";
import {
  control,
  ControlModels,
  group,
  RxFormActions,
} from "@reactables/forms";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { AuthenticationPayload } from "../Models/authenticationForm.model";

export interface AuthenticationFormState {
  submitTouched: boolean;
  submission: RequestState<unknown>;
  form: ControlModels.Form<AuthenticationPayload>;
}

export type AuthenticationFormActions = {
  form: RxFormActions;
  touchSubmit: () => void;
  submit: (payload: { formValue: AuthenticationPayload }) => void;
  reset: () => void;
};

export const RxAuthenticationForm = ({
  action,
}: {
  action: (formValue: AuthenticationPayload) => Promise<any>;
}): Reactable<AuthenticationFormState, AuthenticationFormActions> => {
  const [submission$, { send: submit, resetState }, actions$] = RxRequest<
    { formValue: AuthenticationPayload },
    unknown
  >({
    name: "rxAuthennticationSubmission",
    effect: (action$) =>
      action$.pipe(
        map(({ payload: { formValue } }) => from(action(formValue)))
      ),
  });

  const resetState$ = actions$.pipe(ofTypes(["resetState"]));

  const [form$, formActions] = FormBuilders.build(
    group({
      controls: {
        subscriberNumber: control({
          initialValue: "",
          validators: ["required"],
          normalizers: ["alphaNumeric"],
        }),
        phoneNumber: control({
          initialValue: "",
          validators: ["required", "phoneNumber"],
          normalizers: ["normalizePhone"],
        }),
        email: control({
          initialValue: "",
          validators: ["required", "email"],
          normalizers: ["normalizeEmail"],
        }),
        lastFour: control({
          initialValue: "",
          validators: ["required", "fourDigits"],
          normalizers: ["normalize4Digits"],
        }),
      },
    }),
    {
      name: "rxAuthenticationForm",
      providers: formProviders,
      sources: [resetState$],
      reducers: {
        resetState: ({ resetControl }, state) => resetControl(state, []),
      },
    }
  ) as Reactable<ControlModels.Form<AuthenticationPayload>, RxFormActions>;

  const [submitTouched$, { toggleOn: touchSubmit }] = RxToggle(false, {
    sources: [resetState$],
    reducers: {
      resetState: () => false,
    },
  });

  const state$: Observable<AuthenticationFormState> = combineLatest({
    form: form$,
    submission: submission$,
    submitTouched: submitTouched$,
  });

  const actions = {
    form: formActions,
    submit,
    touchSubmit,
    reset: resetState,
  };

  return [state$, actions];
};
